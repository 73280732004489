import CiTable from './ci-table.vue';

export { default as CiTableTooltip } from './ci-table-tooltip.vue';
export { default as CiTableColumn } from './ci-table-column.vue';
export { default as CiTableCell } from './ci-table-cell.vue';
export { default as DATA_TYPE } from './constants/data-type.constant';
export type { default as ICell } from './interfaces/cell.interface';
export type { default as IColumn } from './interfaces/column.interface';
export type { default as ITableSection } from './interfaces/table-section.interface';
export type { default as ITableData } from './interfaces/table-data.interface';
export type { default as ITableConfig } from './interfaces/table-config.interface';
export type { default as ICellSize } from './interfaces/cell-size.interface';
export type { default as IIndicatorValue } from './interfaces/indicator-value.interface';
export type { default as IIndicators } from './interfaces/indicators.interface';
export type { default as ITooltip } from './interfaces/tooltip.interface';

export default CiTable;
