
import { Component, Vue, Prop } from 'vue-property-decorator';
import type {
    ITableData, ITableConfig, ITooltip, ICellSize,
} from './interfaces';
import CiTableTooltip from './ci-table-tooltip.vue';
import CiTableActiveRow from './ci-table-active-row.vue';
import CiTableColumn from './ci-table-column.vue';

interface HoverInfo {
    rowIndex: number;
    columnIndex: number | number[];
    isSubColumn: boolean;
    domRect: DOMRect;
}

@Component({
    components: {
        CiTableColumn,
        CiTableTooltip,
        CiTableActiveRow,
    },
})
export default class CiTable extends Vue {
    @Prop({
        required: true,
        type: Array,
    })
    tableData!: ITableData;

    @Prop({
        required: true,
    })
    tableConfig!: ITableConfig;

    @Prop({
        default: null,
    })
    date!: Date;

    @Prop({
        type: Boolean,
        default: true,
    })
    isLoading!: boolean;

    activeRow: {
        height: string | null;
        activeIndex: number;
        offset?: number;
    } = {
        height: null,
        activeIndex: -1,
        offset: 0,
    };
    public tooltipData: ITooltip = {
        title: null,
        items: null,
        elementRect: null,
        isVisible: false,
    };

    private disabledColumns: string[] = [];

    startDragScroll(e: MouseEvent) {
        e.preventDefault();
        const tableElement = e.currentTarget as HTMLDivElement;

        const moveListener = (e: MouseEvent) => {
            tableElement.scrollLeft -= e.movementX;
        };

        window.addEventListener('mousemove', moveListener);

        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', moveListener);
        }, { once: true });
    }

    handleRowHover(hoverInfo: HoverInfo, tableIndex: number) {
        const { rowIndex } = hoverInfo;
        const { isSubColumn } = hoverInfo;

        this.$emit('hoverRow', { tableIndex, hoverInfo });

        let tableRow = 1 + rowIndex;

        if (isSubColumn) {
            tableRow += 1;
        }

        let activeRowHeight = '0';
        let cellSize: ICellSize;

        if (!this.tableConfig.cellSize) {
            activeRowHeight = '50px';
            this.activeRow = {
                height: '50px',
                activeIndex: tableRow,
            };
        } else {
            if (Array.isArray(this.tableConfig.cellSize)) {
                [cellSize] = this.tableConfig.cellSize;
            } else {
                ({ cellSize } = this.tableConfig);
            }

            const { length } = cellSize.height;
            const configRowIndex = tableRow > length - 1 ? length - 1 : tableRow;

            activeRowHeight = cellSize.height[configRowIndex];

            this.activeRow = {
                height: activeRowHeight,
                activeIndex: tableRow,
            };
        }
    }

    handleMouseLeave() {
        this.activeRow = {
            activeIndex: -1,
            height: null,
        };
        this.tooltipData = {
            ...this.tooltipData,
            isVisible: false,
        };
    }

    handleWheel(e: WheelEvent) {
        const { currentTarget } = e;
        if (!/sticky/.test((currentTarget as HTMLElement).className)) {
            return;
        }

        e.preventDefault();
        const { tableSection } = this.$refs as { [key: string]: Element[] };
        tableSection[0].scrollTo({ top: tableSection[0].scrollTop + e.deltaY });
    }

    handleTooltipChange(e: ITooltip) {
        this.tooltipData = { ...e };
    }

    handleDisableColumns(columnTitiles: string[]) {
        this.disabledColumns = [...columnTitiles];
        this.$emit('columnDisabled', this.disabledColumns);
    }

    resetDisabledColumns() {
        this.handleDisableColumns([]);
    }

    cellSize(configIndex: number) {
        if (!this.tableConfig.cellSize) {
            return undefined;
        }

        if (!Array.isArray(this.tableConfig.cellSize)) {
            return this.tableConfig.cellSize as ICellSize;
        }

        if (configIndex > (this.tableConfig.cellSize.length - 1)) {
            return this.tableConfig.cellSize[this.tableConfig.cellSize.length - 1];
        }

        return this.tableConfig.cellSize[configIndex];
    }

    isColumnDisabled(title: string) {
        return this.disabledColumns.find(disabledColumn => disabledColumn === title);
    }

    scrollToRow(rowIndex: number) {
        const { table } = this.$refs as { table: HTMLDivElement };
        const row = table.querySelector(`[data-row="${rowIndex}"]`) as HTMLDivElement;

        if (row) {
            table.scrollTop = row.offsetTop - 50;
        }
    }
}
