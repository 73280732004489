
import { Component, Vue, Prop } from 'vue-property-decorator';
import DayBetterIndicators from '@/modules/rates/components/table/day-better-indicators.vue';
import {
    ICell, IIndicators,
} from './interfaces';
import ProviderCard from '../ui-kit/provider-card.vue';

@Component({
    components: {
        DayBetterIndicators,
        ProviderCard,
    },
})
export default class CiTableCell extends Vue {
    @Prop({
        required: false,
        type: [String, Object],
    })
    cellData?: string | ICell;

    @Prop({
        required: false,
        type: String,
    })
    height?: string;

    @Prop({
        required: false,
        type: String,
    })
    width?: string;

    @Prop({
        required: false,
        type: String,
    })
    minWidth?: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    isLoading!: boolean;

    @Prop({
        type: Boolean,
        default: true,
    })
    isBlank!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    bold!: boolean;

    private mouseDownTarget: EventTarget | null = null;
    private mouseDownTimestamp = 0;
    private readonly maxTimestampDiff = 200;

    handleMouseDown(e: MouseEvent) {
        this.mouseDownTarget = e.target;
        this.mouseDownTimestamp = e.timeStamp;
    }

    handleMouseUp(e: MouseEvent) {
        if (e.target !== this.mouseDownTarget || e.timeStamp - this.mouseDownTimestamp > this.maxTimestampDiff) {
            return;
        }

        if (!this.cellData) {
            return;
        }

        if (this.cellData !== 'string' && (this.cellData as ICell).onClick) {
            (this.cellData! as ICell).onClick!();
        }
    }

    handleHover(event: MouseEvent) {
        if (!this.cellData) return;
        const cellData = this.cellData as ICell;

        const domRect = (this.$refs.cell as HTMLElement).getBoundingClientRect() as DOMRect;
        this.$emit('mouseenter', domRect);

        if (cellData.onHover) {
            cellData.onHover(event, cellData, domRect);
        }
    }

    handleMouseLeave(cellData: ICell) {
        if (!cellData) return;
        this.$emit('mouseleave');

        if (cellData.onLeave) {
            cellData.onLeave();
        }
    }

    get useSlot() {
        if (!this.cellData
            || (typeof this.cellData !== 'string' && (!(this.cellData as ICell).value && !(this.cellData as ICell).icon))
        ) {
            return true;
        }
        return false;
    }

    get isTooltipShown() {
        const indicators = (this.cellData && this.cellData !== 'string') ? (this.cellData as ICell).indicators : null;

        if (!indicators) {
            return false;
        }

        const {
            mealType, numberOfGuests, cancellation, losRestriction, occupancy,
        } = indicators;

        return !!mealType || !!numberOfGuests || !!cancellation || !!losRestriction || !!occupancy;
    }

    get tooltipIndicators() {
        if (!this.cellData || (this.cellData !== 'string' && !(this.cellData as ICell).indicators)) {
            return null;
        }

        return Object.entries((this.cellData as ICell).indicators as IIndicators)
            .map(([_, value]) => value)
            .filter(value => value);
    }
}
