var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{ref:"table",staticClass:"table",class:{ 'skeleton-container skeleton-container--gray': _vm.isLoading },style:({
            height: _vm.tableConfig.height,
            width: _vm.tableConfig.width,

            '--row-active-index': _vm.activeRow.activeIndex,
            '--row-height': _vm.activeRow.height,
            '--row-offset': _vm.activeRow.offset || 0,
        }),on:{"mouseleave":_vm.handleMouseLeave,"mousedown":_vm.startDragScroll}},[_vm._l((_vm.tableData),function(tableSection,tableIndex){return _c('section',{key:tableSection.id,ref:`tableSection${tableSection.isSticky ? 'Sticky' : ''}`,refInFor:true,class:{
                'table-section': true,
                sticky: tableSection.isSticky
            }},[(tableSection.columns.length === 0 && _vm.tableData[tableIndex - 1])?[_c('div',{staticClass:"column-wrapper"},[_c('CiTableColumn',{attrs:{"columnData":_vm.tableData[tableIndex - 1].columns[0],"cellSize":_vm.cellSize(tableIndex),"columnIndex":0,"columnsLength":1,"isLoading":false,"disabledColumns":[],"growLastColumn":true,"isBlank":true},on:{"leaveCell":_vm.handleMouseLeave,"hoverRow":(e) => _vm.handleRowHover(e, tableIndex),"tooltipChange":_vm.handleTooltipChange,"disableColumns":_vm.handleDisableColumns}})],1)]:_vm._l((tableSection.columns),function(column,index){return _c('div',{key:column.title,staticClass:"column-wrapper"},[(!_vm.isColumnDisabled(column.title))?_c('CiTableColumn',{attrs:{"columnData":column,"cellSize":_vm.cellSize(tableIndex),"columnIndex":index,"boldRow":tableSection.boldRow,"columnsLength":tableSection.columns.length,"isLoading":_vm.isLoading,"disabledColumns":_vm.disabledColumns,"growLastColumn":!tableSection.isSticky},on:{"leaveCell":_vm.handleMouseLeave,"hoverRow":(e) => _vm.handleRowHover(e, tableIndex),"tooltipChange":_vm.handleTooltipChange,"disableColumns":_vm.handleDisableColumns}}):_vm._e()],1)})],2)}),_c('CiTableTooltip',{attrs:{"title":_vm.tooltipData.title,"items":_vm.tooltipData.items,"date":_vm.tooltipData.date,"elementRect":_vm.tooltipData.elementRect,"isVisible":_vm.tooltipData.isVisible}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }